.container {
  width: 1170px;
  margin: 0 auto 40px auto;
  box-sizing: border-box;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
  border-radius: 5px;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .title {
    font-size: 20px;
    color: #333;
  }

  .right {
    & > :first-child {
      margin-right: 10px;
      padding-right: 10px;
    }
  }
}

table {
  font-size: 14px;
  width: 100%;
  border-spacing: 0;
}

th {
  color: #444;
}

th, td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:last-child td {
  border-bottom: 0;
}

th:first-child, td:first-child {
  padding-left: 0;
}

.paging {
  display: flex;
  margin-top: 20px;

  span {
    margin-left: auto;
  }
}
