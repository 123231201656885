.root {
  width: 295px;
  margin: 45px auto;
  background: #fff;
  padding: 25px 45px 30px 45px;
}

.title {
  font-weight: 400;
  font-size: 16px;
  margin: 5px 0 30px;
  text-align: center;
}

.field {
  margin-bottom: 15px;
}
