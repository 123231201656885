.container {
  width: 600px;
  margin: 50px auto 0 auto;
  box-sizing: border-box;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
  border-radius: 5px;
  text-align: center;

  .error {
    font-size: 21px;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
  }
}
