.field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .label {
    font-size: 14px;
    color: #333;
    width: 120px;

    & + span {
      flex-grow: 1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
